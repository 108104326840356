import React, { useState, useEffect } from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import SearchResultHook from '../hooks/search-result-hook'
import { Link } from 'gatsby'
import BlogPostHook from '../hooks/blogpost-hook'
import ArticlePreview from '../components/article-preview'
import SearchForm from '../components/search-form'

const Default = () => {
  return (
    <div className="wrap px-6 mx-auto py-8 max-w-640 md:px-20 md:pt-12 md:pb-24 min-h-full-content">
      <h2 className="mt-20 uppercase font-normal tracking-widest text-gray-33 text-lg">
        請在下方輸入搜尋文字：
      </h2>
      <div className="mt-10">
        <SearchForm />
      </div>
    </div>
  )
}

const Query = ({ query }) => {
  // const [results,setResults] = useState([])
  const results = SearchResultHook({ query })
  // setQueryStr(query)

  const resultsIds = results.map((node) => node.id)
  const posts = BlogPostHook(resultsIds)

  return (
    <div className="wrap px-6 mx-auto py-8 min-h-full-content md:px-20 md:pt-12 md:pb-24">
      {posts.length > 0 ? (
        <div>
          <div className="py-10 md:py-20">
            <h1 className="uppercase font-normal tracking-widest text-gray-33 text-20 md:text-36">
              "{query}" 的搜尋結果：
            </h1>
          </div>
          <ul className="posts">
            {posts.map(({ node }) => {
              return (
                <li
                  key={node.slug}
                  className="mb-6 last:mb-8 md:mb-16 md:last:mb-16"
                >
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ul>
        </div>
      ) : (
        <div className="py-10 md:py-20">
          <h1 className="uppercase font-normal tracking-widest text-gray-33 text-20 md:text-36">
            喔喔...沒有任何 "{query}" 的搜尋結果
          </h1>
        </div>
      )}

      {/*<pre>*/}
      {/*  {JSON.stringify(results,null,4)}*/}
      {/*</pre>*/}
    </div>
  )
}

export default ({ data }) => {
  return (
    <Layout>
      {/*<Link*/}
      {/*  to="/search/footer"*/}
      {/*>FOOTER</Link>*/}
      {/*<Link to="/search/ryzen">Search Tester</Link>*/}
      <Router basepath="/search">
        <Query path="/:query" />
        <Default path="/" />
      </Router>
    </Layout>
  )
}
