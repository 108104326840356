import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLunr } from 'react-lunr'

const SearchResultHook = ({ query }) => {
  if (query === '') {
    return false
  }

  const data = useStaticQuery(graphql`
    {
      LunrIndex
    }
  `)
  const index = data.LunrIndex.index
  const store = data.LunrIndex.store
  const searchModel = data.LunrIndex.documents
  // const results = useLunr(query, index)
  const results = useLunr(query, index, store)

  if (results.length === 0) {
    console.log('custom search')
    for (const _data of searchModel) {
      for (const key of Object.keys(_data)) {
        const findresult = _data[key].search(query)
        if (findresult >= 0) {
          results.push(store[_data.id])
          break
        }
      }
    }
  }

  console.log(query, results)

  return results
}

export default SearchResultHook
