import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const BlogPostHook = (ids = null) => {
  const data = useStaticQuery(graphql`
    {
      posts: allContentfulBlogPost(
        sort: { fields: [publishDate], order: DESC }
      ) {
        edges {
          node {
            id
            title
            slug
            publishDate(formatString: "D MMMM, YYYY")
            heroImage {
              fluid(maxWidth: 870, maxHeight: 575, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid_withWebp
              }
              fixed(
                width: 870
                height: 575
                quality: 10
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
            category {
              slug
              title
              titleTw
            }
            body {
              body
              childMarkdownRemark {
                html
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  if (!ids || !Array.isArray(ids) || ids.length === 0) {
    return []
  }

  const posts = data.posts.edges

  return posts.filter((edge) => {
    return ids.includes(edge.node.id)
  })
}

export default BlogPostHook
