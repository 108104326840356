import React, { useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Excerpt from '../components/excerpt'

export default ({ article }) => {
  // console.log(article)
  const [imageLoad, setImageLoad] = useState(false)
  return (
    <div className="article-preview md:flex">
      <div className="md:w-1/2">
        <div className="relative pt-img">
          <Link to={`/story/${article.slug}`}>
            <div className="absolute inset-0">
              <Img
                alt={article.title}
                fluid={article.heroImage.fluid}
                onLoad={() => {
                  setImageLoad(true)
                }}
                className={`w-full max-w-full h-full max-h-full transition-all duration-700 delay-500 ease-out ${
                  imageLoad ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </div>
          </Link>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="px-3 md:pr-0 md:pl-16 md:flex md:flex-col md:justify-between md:h-full">
          <div>
            <h3 className="my-3 text-17-25 text-gray-33 font-medium md:text-25-43 md:tracking-wide md:mt-0">
              <Link to={`/story/${article.slug}`}>{article.title}</Link>
            </h3>
            {/*<div className="text-15-22 text-gray-4d font-regular md:text-15-22">*/}
            {/*  <Excerpt str={article.body.childMarkdownRemark.html}/>*/}
            {/*</div>*/}
            <div
              className="text-15-22 text-gray-4d font-regular md:text-15-22"
              dangerouslySetInnerHTML={{
                __html: article.description.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className="flex items-center justify-between my-3 md:block md:my-0">
            <div className="text-12 text-gray-4d font-light md:text-14">
              <Link to={`/stories/${article.category.slug}`}>
                {article.category.title}
                {article.category.titleTw
                  ? ' ' + article.category.titleTw
                  : null}
              </Link>
            </div>
            <div className="text-12 text-gray-99 font-light md:text-14 md:mt-2">
              {article.publishDate}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
